import { QRCodeSVG } from "qrcode.react";
import React from "react";

export default function AmazonRedirectPopup({
  toggleAmazonRedirectPopup,
  title,
}) {
  return (
    <div
      className={`pointer-events-none hidden fixed pt-16 inset-0 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden sm:flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          toggleAmazonRedirectPopup();
        }}
        className={` pointer-events-auto hide-button-flash overscroll-contain motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute`}
        disabled={false}
      />
      <form
        className={`motion-safe:animate-fadeUp pointer-events-auto h-auto overflow-x-hidden max-h-full flex-shrink overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-center justify-start z-40 rounded-xl p-8 pt-0 short:px-4 short:pb-4 sm:p-10 bg-white shadow-xl rounded-b-none sm:rounded-3xl`}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            toggleAmazonRedirectPopup();
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>
        <p className="text-gray-700 text-2xl short:text-xl text-center font-bold w-full leading-tight short:leading-tight">
          {"Hi friend!"}
        </p>
        <p className="text-gray-500 text-center w-full mt-1 leading-tight short:text-sm">
          Please check out{" "}
          <a
            className="underline text-blue-600 hover:text-blue-700 transition-all"
            href="https://a.co/d/iBvxpa8"
            target="_blank"
            rel="noreferrer"
          >
            The Lucky Pet Album
          </a>{" "}
          on Amazon!
        </p>

        <QRCodeSVG
          value={"https://a.co/d/iBvxpa8"}
          level="M"
          bgColor="transparent"
          className="w-1/2 mt-4 mb-2 h-full hidden sm:block rounded-md"
          imageSettings={{
            src: "https://luckypet-media.s3.amazonaws.com/luckypet-qr-code-logo.png",
            width: 40,
            height: 40,
          }}
        />

        <div className="flex mt-3 w-full sm:w-auto items-center justify-center flex-col sm:flex-row sm:space-y-0 space-y-4 sm:space-x-4">
          <a
            href="https://a.co/d/iBvxpa8"
            target="_blank"
            rel="noreferrer"
            className="flex px-4 py-4 space-y-1 flex-col items-center justify-center w-full sm:w-48 h-14 bg-black hover:bg-gray-700 transition-all text-white rounded-lg"
          >
            <div className="text-xs truncate shrink-0">Order now at</div>
            <svg
              className="h-full shrink-0"
              viewBox="0 0 603 182"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_30_7)">
                <path
                  d="M374.006 142.184C339.007 167.982 288.277 181.746 244.6 181.746C183.357 181.746 128.223 159.094 86.5123 121.421C83.2352 118.458 86.1715 114.421 90.104 116.728C135.118 142.918 190.777 158.675 248.27 158.675C287.045 158.675 329.7 150.652 368.92 134.005C374.845 131.488 379.8 137.885 374.006 142.184Z"
                  fill="#FF9900"
                />
                <path
                  d="M388.557 125.536C384.1 119.821 358.984 122.836 347.711 124.173C344.277 124.592 343.752 121.603 346.846 119.454C366.849 105.375 399.673 109.439 403.5 114.158C407.328 118.903 402.504 151.805 383.707 167.509C380.823 169.921 378.07 168.636 379.355 165.438C383.576 154.899 393.04 131.277 388.557 125.536Z"
                  fill="#FF9900"
                />
                <path
                  d="M348.498 20.0657V6.38054C348.498 4.30941 350.071 2.91992 351.958 2.91992H413.227C415.193 2.91992 416.766 4.33563 416.766 6.38054V18.0995C416.74 20.0657 415.088 22.635 412.152 26.6986L380.403 72.0275C392.201 71.7391 404.654 73.4957 415.351 79.5255C417.763 80.8888 418.418 82.8813 418.601 84.8476V99.4503C418.601 101.443 416.399 103.776 414.092 102.57C395.242 92.6864 370.205 91.6115 349.363 102.675C347.239 103.829 345.011 101.521 345.011 99.529V85.6603C345.011 83.4319 345.037 79.6304 347.265 76.2484L384.048 23.5001H352.037C350.071 23.5001 348.498 22.1107 348.498 20.0657Z"
                  fill="#fff"
                />
                <path
                  d="M124.999 105.455H106.359C104.576 105.324 103.16 103.987 103.029 102.282V6.61718C103.029 4.70335 104.629 3.18276 106.621 3.18276H124.003C125.812 3.26136 127.254 4.6509 127.385 6.38121V18.8867H127.726C132.261 6.80068 140.782 1.16406 152.265 1.16406C163.931 1.16406 171.219 6.80068 176.463 18.8867C180.972 6.80068 191.223 1.16406 202.208 1.16406C210.02 1.16406 218.567 4.38873 223.784 11.6246C229.683 19.6732 228.477 31.3659 228.477 41.6167L228.451 101.994C228.451 103.908 226.851 105.455 224.859 105.455H206.245C204.384 105.324 202.889 103.829 202.889 101.994V51.2907C202.889 47.2533 203.256 37.186 202.365 33.3583C200.975 26.9352 196.807 25.1263 191.406 25.1263C186.897 25.1263 182.178 28.1412 180.264 32.9651C178.35 37.789 178.534 45.8638 178.534 51.2907V101.994C178.534 103.908 176.935 105.455 174.942 105.455H156.328C154.441 105.324 152.972 103.829 152.972 101.994L152.946 51.2907C152.946 40.6204 154.703 24.9165 141.463 24.9165C128.066 24.9165 128.591 40.2272 128.591 51.2907V101.994C128.591 103.908 126.991 105.455 124.999 105.455Z"
                  fill="#fff"
                />
                <path
                  d="M469.514 1.16406C497.173 1.16406 512.143 24.9165 512.143 55.1183C512.143 84.2977 495.6 107.447 469.514 107.447C442.354 107.447 427.567 83.6947 427.567 54.0959C427.567 24.3135 442.537 1.16406 469.514 1.16406ZM469.672 20.6956C455.934 20.6956 455.069 39.4144 455.069 51.0809C455.069 62.7737 454.885 87.7321 469.514 87.7321C483.96 87.7321 484.641 67.5976 484.641 55.3281C484.641 47.2533 484.301 37.6055 481.862 29.9502C479.765 23.2911 475.597 20.6956 469.672 20.6956Z"
                  fill="#fff"
                />
                <path
                  d="M548.008 105.455H529.446C527.585 105.324 526.091 103.829 526.091 101.994L526.064 6.30256C526.222 4.54603 527.769 3.18276 529.656 3.18276H546.933C548.558 3.26136 549.896 4.36252 550.263 5.85688V20.4859H550.603C555.821 7.40367 563.135 1.16406 576.008 1.16406C584.371 1.16406 592.524 4.179 597.768 12.4373C602.644 20.0926 602.644 32.9651 602.644 42.2196V102.44C602.434 104.118 600.887 105.455 599.052 105.455H580.359C578.655 105.324 577.24 104.065 577.056 102.44V50.478C577.056 40.0174 578.262 24.7068 565.39 24.7068C560.854 24.7068 556.686 27.748 554.615 32.3621C551.993 38.2085 551.652 44.0286 551.652 50.478V101.994C551.626 103.908 550 105.455 548.008 105.455Z"
                  fill="#fff"
                />
                <path
                  d="M299.656 59.7583V55.7209C286.18 55.7209 271.945 58.6048 271.945 74.4922C271.945 82.5408 276.113 87.9939 283.27 87.9939C288.514 87.9939 293.206 84.7692 296.169 79.5258C299.839 73.0765 299.656 67.0204 299.656 59.7583ZM318.453 105.192C317.221 106.293 315.438 106.372 314.049 105.638C307.862 100.499 306.76 98.1136 303.352 93.211C293.128 103.645 285.892 106.765 272.626 106.765C256.948 106.765 244.731 97.0911 244.731 77.7169C244.731 62.5898 252.937 52.2865 264.604 47.2529C274.724 42.796 288.854 42.0095 299.656 40.7773V38.3654C299.656 33.9347 299.997 28.6914 297.401 24.8637C295.12 21.4293 290.768 20.0136 286.941 20.0136C279.836 20.0136 273.491 23.6577 271.945 31.2082C271.63 32.8861 270.398 34.5377 268.72 34.6164L250.63 32.6763C249.11 32.3355 247.432 31.1033 247.851 28.77C252.02 6.85275 271.813 0.246094 289.536 0.246094C298.607 0.246094 310.457 2.65804 317.614 9.52685C326.685 17.9949 325.82 29.2944 325.82 41.5901V70.6383C325.82 79.3685 329.438 83.1962 332.846 87.9152C334.052 89.5931 334.314 91.6118 332.794 92.8702C328.992 96.0425 322.228 101.941 318.506 105.245L318.453 105.192Z"
                  fill="#fff"
                />
                <path
                  d="M55.2885 59.7583V55.7209C41.8131 55.7209 27.5773 58.6048 27.5773 74.4922C27.5773 82.5408 31.7458 87.9939 38.903 87.9939C44.1464 87.9939 48.8392 84.7692 51.8017 79.5258C55.472 73.0765 55.2885 67.0204 55.2885 59.7583ZM74.086 105.192C72.8538 106.293 71.0711 106.372 69.6816 105.638C63.4944 100.499 62.3933 98.1136 58.9851 93.211C48.7605 103.645 41.5247 106.765 28.259 106.765C12.5813 106.765 0.364258 97.0911 0.364258 77.7169C0.364258 62.5898 8.57013 52.2865 20.2366 47.2529C30.3563 42.796 44.4872 42.0095 55.2885 40.7773V38.3654C55.2885 33.9347 55.6294 28.6914 53.0339 24.8637C50.753 21.4293 46.401 20.0136 42.5734 20.0136C35.4686 20.0136 29.1241 23.6577 27.5773 31.2082C27.2627 32.8861 26.0305 34.5377 24.3527 34.6164L6.26305 32.6763C4.74247 32.3355 3.06459 31.1033 3.48406 28.77C7.65254 6.85275 27.4462 0.246094 45.1688 0.246094C54.2399 0.246094 66.0899 2.65804 73.2471 9.52685C82.3181 17.9949 81.4529 29.2944 81.4529 41.5901V70.6383C81.4529 79.3685 85.0709 83.1962 88.4791 87.9152C89.685 89.5931 89.9472 91.6118 88.4266 92.8702C84.6252 96.0425 77.8613 101.941 74.1384 105.245L74.086 105.192Z"
                  fill="#fff"
                />
              </g>
              <defs>
                <clipPath id="clip0_30_7">
                  <rect width="603" height="182" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        {/* <div className="w-full mt-2 sm:hidden">
          <SubmitButton onClickFunc={(e) => {}} title={"Download"} />
        </div> */}
      </form>
    </div>
  );
}
