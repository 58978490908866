import React, { useEffect } from "react";
import AmazonRedirectPopup from "../../components/creator/AmazonRedirectPopup";
import lucky from "../../images/lucky.png";
import { getMobileOperatingSystem } from "../../util/hooks";

export default function AmazonRedirect() {
  const OS = getMobileOperatingSystem();
  useEffect(() => {
    if (OS === "Android" || OS === "iOS") {
      const appStoreLink = "https://a.co/d/iBvxpa8";

      window.location.href = appStoreLink;
    }
  }, [OS]);

  return (
    <div
      className={`h-full bg-black sm:bg-light-blue relative w-screen overflow-hidden min-h-screen`}
    >
      <AmazonRedirectPopup toggleDownloadAppPopup={() => {}} />
      <img
        src={lucky}
        alt="Lucky the Kitten"
        className="shrink-0 hidden sm:block absolute bottom-0 inset-auto"
      />
    </div>
  );
}
