import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { CustomerAuthProvider } from "./context/customerAuth";
import { GuestAuthProvider } from "./context/guestAuth";
import AmazonRedirect from "./pages/creator/AmazonRedirect";
import NotFound from "./pages/creator/NotFound";

// import  Blank from "./pages/creator/Blank";
const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_51KFOC1KBkPH8aLr0iKDoAfO6h8Zv5P6zqdQKX6Q7qchQ7bHhvm37VFMRf4rjnLGMVIHiv8wBuroxuj0foikD23LM00YQkA8xcf"
    : "pk_test_51KFOC1KBkPH8aLr0aKQUNxy0dz1i1J17hoInfei2xZ1yp02BT1NMaseSwZTOUlkk8PsI9zXTI0JmLwBCleMQrQXn00uJMCUaxC"
);

function App() {
  // const handleGotKeyboardSize = (e) => {
  //   if (isRunningInRNWebView) {
  //     if (e && e.data && e?.data?.keyboardHeight) {
  //       localStorage.setItem("keyboardHeight", e?.data?.keyboardHeight || 0);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("message", handleGotKeyboardSize);

  //   return () => {
  //     document.removeEventListener("message", handleGotKeyboardSize);
  //   };
  // }, []);

  // const handleGotTopbarHeight = (e) => {
  //   if (isRunningInRNWebView) {
  //     // console.log("kooko");
  //     if (e && e.data && e?.data?.topbarHeight) {
  //       localStorage.setItem("topbarHeight", e?.data?.topbarHeight || 0);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("message", handleGotTopbarHeight);

  //   return () => {
  //     document.removeEventListener("message", handleGotTopbarHeight);
  //   };
  // }, []);

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          {/* 
          <Route path="/download-app" element={<DownloadAppRedirect />} />

          <Route path="/support" element={<Support />} />
          <Route
            exact
            path="/search"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Search />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/search/:categoryShortForm"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Search />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/addFriends"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <AddFriends />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/addFriends/org/:orgId"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <AddFriends />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/addFriends/topic/:topicId"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <AddFriends />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/addFriends/battle/:quizId"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <AddFriends />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/completeRegistration/:step"
            element={
              <CustomerAuthProvider>
                <CompleteRegistration />
              </CustomerAuthProvider>
            }
          />
          <Route
            exact
            path="/completeRegistration/:step/redirect/:prevPath"
            element={
              <CustomerAuthProvider>
                <CompleteRegistration />
              </CustomerAuthProvider>
            }
          />
          <Route
            exact
            path="/logout"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <LogoutCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/tkl"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <TriviaNight />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/leaderboard"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Leaderboard />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/notifications"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Notifications />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/leaderboard/:topicShortForm"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Leaderboard />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/leaderboard/:topicShortForm/:category"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Leaderboard />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/leaderboard/:topicShortForm/:category/:subCategory"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Leaderboard />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/register"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/register/:joinedFor"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/register/:joinedFor/redirect/:prevPath"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/register/redirect/:prevPath"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/signup/:joinedFor"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <RegisterCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/resetPassword"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <ResetCustomerPassword />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/resetPassword/:resetCode"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <ResetCustomerPassword />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/resetSellerPassword"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <ResetSellerPassword />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/resetSellerPassword/:resetCode"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <ResetSellerPassword />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <LoginCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/loginSeller"
            element={
              <BrandContactAuthProvider>
                <LoginSeller />
              </BrandContactAuthProvider>
            }
          />
          <Route
            exact
            path="/registerSeller"
            element={
              <BrandContactAuthProvider>
                <RegisterSeller />
              </BrandContactAuthProvider>
            }
          />

          <Route
            exact
            path="/sellerDashboard"
            element={
              <BrandContactAuthProvider>
                <SellerDashboard />
              </BrandContactAuthProvider>
            }
          />
          <Route
            exact
            path="/sellerSettings"
            element={
              <BrandContactAuthProvider>
                <SellerAccountSettings />
              </BrandContactAuthProvider>
            }
          />
          <Route
            exact
            path="/login/redirect/:prevPath"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <LoginCustomer />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/adminLogin"
            element={
              <AdminAuthProvider>
                <LoginAdmin />
              </AdminAuthProvider>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <CustomerAccountSettings />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/settings/:shouldUnsubscribe"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <CustomerAccountSettings />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/adminDashboard/:typeOfData"
            element={
              <AdminAuthProvider>
                <AdminDashboard />
              </AdminAuthProvider>
            }
          />
          <Route
            exact
            path="/adminDashboard"
            element={
              <AdminAuthProvider>
                <AdminDashboard />
              </AdminAuthProvider>
            }
          />
          <Route
            exact
            path="/topic/:shortForm"
            element={
              <CustomerAuthProvider>
                <Topic />
              </CustomerAuthProvider>
            }
          />
          <Route
            exact
            path="/org/:nameForUrl"
            element={
              <CustomerAuthProvider>
                <Organization />
              </CustomerAuthProvider>
            }
          />
          <Route
            exact
            path="/battle/:quizId"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Battle />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/focus/org/:orgNameForUrl"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Focus />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          /> */}
          {/* <Route
            exact
            path="/focus/:topicShortForm/:questionId"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Focus />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/focus/:topicShortForm"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Focus />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/focus"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Focus />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/buySubscription"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Focus />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/buySubscription/:subscriptionType"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Focus />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />

          <Route
            exact
            path="/buyTokens"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Focus />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          <Route
            exact
            path="/buyTokens/:numOfCoins"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <Focus />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          /> */}
          <Route
            exact
            path="/"
            element={
              <GuestAuthProvider>
                <CustomerAuthProvider>
                  <AmazonRedirect />
                </CustomerAuthProvider>
              </GuestAuthProvider>
            }
          />
          {/* <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} /> */}
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
